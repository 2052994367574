const $ = require('jquery');
import '../scss/homepage.scss';
import 'slick-carousel/slick/slick.scss';
import 'slick-carousel/slick/slick-theme.scss';
import 'slick-carousel/slick/slick.min';
import 'slick-carousel';

// =============================================================
// CAROUSEL
// =============================================================

$('.slick-product-carousel').slick({
  autoplay: true,
  autoplaySpeed: 5000,
  infinite: true,
  centerMode: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  arrows: true,
  speed: 900,
  dots: false,
  focusOnSelect: true,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
      }
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
      }
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 1,
        dots: true,
        arrows: false,
      }
    }
  ]
});

$('.slick-recipe-carousel').slick({
  autoplay: true,
  autoplaySpeed: 5000,
  infinite: true,
  slidesToShow: 4,
  slidesToScroll: 1,
  arrows: true,
  speed: 900,
  dots: false,
  focusOnSelect: true,
  responsive: [
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 3,
      }
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 2,
        dots: true,
        arrows: false,
      }
    }
  ]
});

// =============================================================
// NAV EFFECT ON SCROLL
// =============================================================

function toggleNavBarClass() {
  const scroll  = $(document).scrollTop();
  const topDist = $('body').position();
  const navbar  = $('#main-menu.homepage-menu');

  if(scroll > topDist.top) {
    navbar.addClass('active');
  } else {
    navbar.removeClass('active');
  }
}

toggleNavBarClass();

$(() => {
  $(document).scroll(() => {
    toggleNavBarClass();
  });
});
